export const QUERY_PARAMS = {
  orderPlaced: "orderPlaced",
  // Listing
  sort: "sort",
  filter: "filter",
  stock: "inStock",
  price: "price",
  priceGte: "gte",
  priceLte: "lte",
  search: "q",
  cursor: "cursor",
  after: "next",
  before: "prev",
} as const;
