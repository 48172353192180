import { generateDataTestId } from "@/lib/tests";
import { type WithChildren, type WithHTMLProp } from "@/lib/types";

import { cn } from "@/styles/lib";

type InputHelperTextProps = WithChildren &
  WithHTMLProp<"className" | "id"> & {
    hasError?: boolean;
    isDisabled?: boolean;
    name: string;
  };

// TODO: Add disabled state upon form processing
export const InputHelperText = ({
  hasError,
  name,
  children,
  isDisabled,
  id,
  className,
}: InputHelperTextProps) => (
  <p
    {...generateDataTestId("helperText", name)}
    className={cn(
      "mt-0.5",
      "text-sm",
      "text-black/55",
      "peer-invalid:text-danger",
      className,
      {
        "text-danger": hasError,
        "text-gray-300": isDisabled,
      }
    )}
    id={id}
  >
    {children}
  </p>
);
