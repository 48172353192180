import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import type { Maybe } from "@/lib/types";

export function random(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const isNotNil = <T extends unknown>(value: Maybe<T>): value is T =>
  !(value === null || typeof value === "undefined");

export const makeFirstLetterUppercase = (text: Maybe<string>) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return "";
};

export const cleanObject = <T extends Record<string, unknown>>(obj: T) =>
  pickBy(obj, identity);

export const isEmptyObject = <T extends Record<string, unknown>>(
  obj: Maybe<T>
): obj is T => !!(obj && Object.keys(obj).length);
