import { Transition, type TransitionClasses } from "@headlessui/react";
import { forwardRef, Fragment } from "react";

import { type WithHTMLProp } from "@/lib/types";

import { cn } from "@/styles/lib";

export const TransitionBackdrop = forwardRef<
  HTMLDivElement,
  WithHTMLProp<"className"> & TransitionClasses
>(
  (
    { className, enterTo, enterFrom, leaveFrom, leaveTo, leave, enter },
    ref
  ) => (
    <Transition.Child
      as={Fragment}
      enter={cn("ease-out duration-300 transition-all", enter)}
      enterFrom={cn("opacity-0", enterFrom)}
      enterTo={cn("opacity-100", enterTo)}
      leave={cn("ease-in duration-200", leave)}
      leaveFrom={cn("opacity-100", leaveFrom)}
      leaveTo={cn("opacity-0", leaveTo)}
    >
      <div
        className={cn(
          "fixed inset-0 bg-black/25 backdrop-blur-xs transition-all",
          className
        )}
        ref={ref}
      />
    </Transition.Child>
  )
);

TransitionBackdrop.displayName = "TransitionBackdrop";
