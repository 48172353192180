"use client";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSwipeable } from "react-swipeable";

import { TransitionBackdrop } from "@/components/TransitionBackdrop";
import { type WithChildren } from "@/lib/types";

import { cn } from "@/styles/lib";

type DrawerProps = WithChildren & {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  side?: "left" | "right";
};

export const Drawer = ({
  isOpen,
  setIsOpen,
  children,
  side = "left",
}: DrawerProps) => {
  const handlers = useSwipeable({
    onSwiped: ({ dir, event }) => {
      const shouldIgnore = (event.target as HTMLElement)?.closest(
        "[data-ignore-swipe]"
      );
      const isLeftSwipe = side === "left" && dir === "Left";
      const isRightSwipe = side === "right" && dir === "Right";
      const isPrevented = event.defaultPrevented;

      if (!shouldIgnore && !isPrevented && (isLeftSwipe || isRightSwipe)) {
        setIsOpen(false);
      }
    },
    trackMouse: true,
    delta: 20,
  });

  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog as="div" className="relative z-20" onClose={setIsOpen}>
        <TransitionBackdrop />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={cn(
                "pointer-events-none fixed inset-y-0 flex w-full",
                {
                  "left-0": side === "left",
                  "right-0": side === "right",
                }
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                {...(side === "left"
                  ? {
                      enterFrom: "-translate-x-full",
                      enterTo: "-translate-x-0",
                      leaveFrom: "-translate-x-0",
                      leaveTo: "-translate-x-full",
                    }
                  : {
                      enterFrom: "translate-x-full",
                      enterTo: "-translate-x-0",
                      leaveFrom: "translate-x-0",
                      leaveTo: "translate-x-full",
                    })}
              >
                <Dialog.Panel
                  className={cn(
                    "pointer-events-auto relative w-full",
                    "sm:w-[90%] md:w-3/4 lg:w-1/2 xl:w-2/5",
                    {
                      "mr-auto": side === "left",
                      "ml-auto": side === "right",
                    }
                  )}
                >
                  <div
                    {...handlers}
                    className="flex h-full flex-col overflow-auto bg-white shadow-xl"
                  >
                    <div className="relative flex-1">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
