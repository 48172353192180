import { makeFirstLetterUppercase } from "@/lib/core";

type DataTestName = string;

export type DataTestType =
  | "banner"
  | "header"
  | "helperText"
  | "card"
  | "carousel"
  | "cell"
  | "checkbox"
  | "chip"
  | "button"
  | "cart"
  | "dialog"
  | "dropdown"
  | "form"
  | "image"
  | "input"
  | "label"
  | "item"
  | "list"
  | "loader"
  | "modal"
  | "radio"
  | "row"
  | "section"
  | "select"
  | "slider"
  | "stepper"
  | "step"
  | "edit"
  | "switch"
  | "table"
  | "accordion"
  | "text"
  | "rangeSlider";

export const DATA_TEST_SELECTOR = "data-testid";

export type DataTestId = Record<typeof DATA_TEST_SELECTOR, string>;

export const generateTestId = (type: DataTestType, name?: DataTestName) => {
  const splitNameBySpace = name?.split(" ");

  const nameFirstLettersUppercase =
    splitNameBySpace?.map(makeFirstLetterUppercase).join("") ?? "";

  return `${type}${nameFirstLettersUppercase}`;
};

export const generateDataTestId = (
  type: DataTestType,
  name?: DataTestName
): DataTestId => ({ [DATA_TEST_SELECTOR]: generateTestId(type, name) });
